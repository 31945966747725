header {
    height: 100vh;
}

.header__container {
    /* text-align: center;
    height: 100%;
    position: relative; */
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
}

/* =============== CTA ================ */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* =============== Socials ============= */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: fixed;
    left: 2rem;
    bottom: 3rem;
}

.header__socials::before{
    content: '';
    width: 1px;
    height: 1rem;
    background: var(--color-primary);
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 1rem;
    background: var(--color-primary);
}

 /* =============== MEDIA QUERIES (Small) =================== */
 @media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials {
        display: none;
    }
    
    video {
        height: 100vh;
    }

    .header__container > h1 {
        font-size: 2rem;
    }
}

 /* =============== MEDIA QUERIES (Medium) =================== */
 @media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }

    video {
        height: 100vh;
    }
    
}