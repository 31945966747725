.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items: left;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: translate(1rem, 6%);
    transition: var(--transition)
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__content {
    display: flex;
    letter-spacing: 1px;
    flex-direction:column;
    margin: auto;
    gap: 2rem;
}

 /* =============== MEDIA QUERIES (Medium) =================== */
 @media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}

/* =============== MEDIA QUERIES (Small) =================== */
@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__content {
        text-align: center;
    }
}