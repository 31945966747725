form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white)
}

button {
    margin-bottom:8rem;
}