.skills__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.skills__container > div {
    background: var(--color-bg-variant);
    padding: 2rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

/* .skills__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
    
} */

.skills__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.skills__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    row-gap: 2rem;
    gap: 2rem;
}

.skills__details {
    display: flex;
    gap:1rem;
}

.skills__details2 {
    display: flex;
    gap:1rem;
    justify-content:center;
}

.ht {
    padding-right:1.8rem;
}


.icons {
    font-size: 22px;
}

/* frontend icons */

.js {
    color: gold;;
}

.htm {
    color: orangered;
}

.cs {
    color: turquoise;
}

.boot {
    color: #9448BC;
}

.re {
    color: skyblue;
}

/* backend icons */

.post {
    color: #4A6FA5;
}

.ex {
    padding-right: .6rem;
}

.node {
    color: lightgreen;
}

.mocha {
    color: burlywood;
}

.chai {
    color: red;
}
 /* =============== MEDIA QUERIES (Medium) =================== */
 @media screen and (max-width: 1024px) {
    .skills__container {
        grid-template-columns: 1fr;
    }

    .skills__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .skills__content {
        padding: 1rem;
    }
}


 /* =============== MEDIA QUERIES (Small) =================== */
 @media screen and (max-width: 600px) {
    .skills__container {
        gap:1rem;
    }

    .skills__container > div {
        width: 100%;
        padding: 2rem 1rem;
        margin: 0 auto;
    }
}
