.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  vertical-align: top;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1rem 0 0;
}

.portfolio__item-CTA {
  display: flex;
  gap: 1rem;
  margin-top: auto;
}

.technologies {
    display: flex;
    margin-top: auto;
}


hr {
  overflow: hidden;
  border: 1px solid var(--color-primary);
  margin-bottom: 1rem;
}

.project-skills {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  row-gap: 0.4rem;
  margin-bottom: 1rem;
}

li {
  font-size: 0.9rem;
  border: 1px solid var(--color-primary-variant);
  padding: 0.2rem;
  text-align: center;
}

/* =============== MEDIA QUERIES (Medium) =================== */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1.2rem;
  }
}

/* =============== MEDIA QUERIES (Small) =================== */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
